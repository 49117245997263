import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { UserService } from 'src/app/core/services/user.service';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CloseRouteDialogMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { takeUntil } from 'rxjs/operators';
import { AvatarUploadComponentOptions, AvatarUploadedFileModel } from '../../avatar-upload/avatar-upload.component';
import CommonTypesHelper from 'src/app/shared/helpers/common-types-helper';
import { StateType } from 'src/app/shared/enums/state-type.enum';
import { TerritorialDivisionsService } from 'src/app/core/services/territorial-divisions.service';
import { TextLanguage } from 'src/app/shared/models/people/text-language.model';
import * as moment from 'moment';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.css']
})

export class ViewUserComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject();
  fechaNacimiento: string | null;
  loggedInUser: ViewUserComponentDto = null as any;   
  translations = {
    man: "",
    women: "",     
  };
  avatarUploadOptions : AvatarUploadComponentOptions;
  
  constructor(
    private userService: UserService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,   
    private translation: L10nTranslationService,    
    private router: Router,
    private messageBusService: MessageBusService,
    private territorialDivisionService: TerritorialDivisionsService
  ) {
    moment.locale(this.locale.language);
    this.avatarUploadOptions = new AvatarUploadComponentOptions();
    this.avatarUploadOptions.requiredFileType = "image/png,image/jpeg";
    this.avatarUploadOptions.selectFileLabel = "añadir foto";
    this.avatarUploadOptions.changeFileLabel = "Editar";
    this.avatarUploadOptions.showFileOnceUpload = true;
  }

  ngOnInit(): void {
    this.translation.onChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
        next: () => {
            this.translations.man = this.translation.translate('man');
            this.translations.women = this.translation.translate('women');

            this.avatarUploadOptions.selectFileLabel = this.translation.translate('shared.viewUser.text8');
            this.avatarUploadOptions.changeFileLabel = this.translation.translate('shared.viewUser.text3');
            this.avatarUploadOptions.changePictureLabel = this.translation.translate('shared.viewUser.text9');
            this.avatarUploadOptions.deleteFileLabel = this.translation.translate('shared.viewUser.text10');
        }
      });

    this.userService.getUserPersonLoggedIn()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(p => {
        if (p) 
          this.loggedInUser = p as ViewUserComponentDto;

        if (p?.idEstadoValidacionIdentidadPersona == StateType.SinValidar) {
          this.loggedInUser.fullName = "";
          this.loggedInUser.sexName = "";
          this.loggedInUser.residenceCountryName = [];
          this.fechaNacimiento = null;
        } else {
          this.loggedInUser.fullName = `${this.loggedInUser.nombre} ${this.loggedInUser.apellido}`;
          let feNacimiento = this.loggedInUser.fechaNacimiento ? new Date(this.loggedInUser.fechaNacimiento) : null;
          this.fechaNacimiento = CommonTypesHelper.dateHasValue(feNacimiento) ? moment(feNacimiento).format('D [de] MMMM, yyyy') : null;
  
          if (CommonTypesHelper.stringHasValue(this.loggedInUser.sexo)) {
              if (this.loggedInUser.sexo.toLocaleLowerCase() == "m") {
                this.loggedInUser.sexName = this.translations.man;
                this.loggedInUser.sexIconClass = "phr-iconset-hombre"; 
              } else {
                this.loggedInUser.sexName = this.translations.women;
                this.loggedInUser.sexIconClass = "phr-iconset-mujer";
              }
          }
        }
        var country = this.territorialDivisionService.getAvailableCountries().find(c => c.idPais == this.loggedInUser.idPaisResidencia);

        if(country){
          this.loggedInUser.residenceCountryName = country.nombreI18n;
        }    
    });

    // On Header Back click
    this.messageBusService.onNavigateBack()
      .pipe(takeUntil(this.ngUnsubscribe))  
      .subscribe(m => {
        this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.messageBusService.refreshProfileMenu();
  }

  onUserEditClick() {
    this.router.navigate(['configuracion/usuario/edicion']);   
  }

  onAvatarUploaded(file: AvatarUploadedFileModel) {
    this.loggedInUser.ubicacionLogo = file?.webUrl;
    this.userService.updateUserLoggedIn(this.loggedInUser);
  }
  
}

export class ViewUserComponentDto extends UserPerson {
    fullName: string;
    sexName: string;
    sexIconClass: string;
    residenceCountryName: TextLanguage[];
}

