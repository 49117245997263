import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ComponentLookup } from '../../decorators/component-lookup.decorator';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { BreakpointObserver } from '@angular/cdk/layout';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { MyOrderSummaryRouteDialogModel } from '../dialogs/route-dialog/route-dialog.component';
import { MedicalOrderService } from 'src/app/core/services/medical-order.service';
import { ActivatedRoute, Data, Params } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { Dependent } from '../../models/people/dependent.model';
import { UserPerson } from '../../models/people/user-person.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UtilsService } from 'src/app/core/services/utils.service';
import { PublicProfileAvatarComponentModel } from '../public-profile-avatar/public-profile-avatar.component';
import { PublicProfileType } from '../../models/people/profile-search-results.model';
import { MEDICAL_REQUEST_STATE, MEDICAL_REQUEST_TYPE, MY_MEDICAL_ORDERS_CARD_TEXT, MY_MEDICAL_ORDERS_BUTTON_TEXT, MY_MEDICAL_ORDERS_TITLE, MY_MEDICAL_ORDER_DETAIL_COUNT_TEXT } from '../../enums/medical-request-type.enum';
import { Person } from '../../models/people/person.model';
import { MedicalOrderViewModel } from '../../models/clinic-history/medical-order.model';
import { BaseService } from 'src/app/core/services/base.service';

@Component({
  selector: 'app-my-medical-orders',
  templateUrl: './my-medical-orders.component.html',
  styleUrls: ['./my-medical-orders.component.css']
})
@ComponentLookup('MyMedicalOrdersComponent')
export class MyMedicalOrdersComponent implements OnDestroy, OnInit {

  loading: boolean;
  items: MedicalOrderItemModel[] = [];
  private ngUnsubscribe = new Subject();
  patientData: Person = new Person;
  patientId: number;

  idTipo: number;
  nombreTipo: string;
  nombreTipoCardText: string;
  nombreTipoButtonText: string;
  nombreConteoDetalle: string;

  tipoSolitudEnum = MEDICAL_REQUEST_TYPE;
  estadoOrdenMedicaEnum = MEDICAL_REQUEST_STATE;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private baseService: BaseService,
    private breakpointObserver: BreakpointObserver,
    private routeDialogService: RouteDialogService,
    private medicalOrderService: MedicalOrderService,
    private route: ActivatedRoute,
    private userService: UserService,
    private utilsService: UtilsService
  ) {
    this.route.data.subscribe({ next: this.routeParamsNext.bind(this) });
  }

  ngOnInit(): void { 

    this.loading = true;

    this.route.queryParams
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((queryParams) => {
      this.configByParams(queryParams);
    },
    error => {
      console.log(error)

      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private configByParams(params: Params){
    if(params["p"]){

      this.patientId = +params["p"];

      this.userService.getUserDependents()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((dependents: Dependent[])=>{

          let dependent = dependents.find(d=> d.idPersona == +params["p"]);

          if(dependent){
            dependent.id = dependent.idPersona;
            this.setPatient(dependent);
            this.getMedicalOrders();
          }
          else {
            this.loading = false;
          }
        });
    }
    else{
      this.userService.getUserPersonLoggedIn()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((userPerson:UserPerson | null)=>{
          if(userPerson){

            this.setPatient(userPerson);
            this.getMedicalOrders();
          }
          else{
            this.loading = false;
          }
        });
    }
  }

  private setPatient(person: Person){
    this.patientData = person;

    this.patientData.ubicacionLogo = person.ubicacionLogo 
      ? person.ubicacionLogo 
      : this.utilsService.getPersonDefaultAvatarUrl(person.fechaNacimiento, person.sexo ?? 'm');
  }

  private routeParamsNext(args: Data): void {
    this.idTipo = args.idsTipoContenidos[0];
    this.nombreTipo = MY_MEDICAL_ORDERS_TITLE[this.idTipo];
    this.nombreTipoCardText = MY_MEDICAL_ORDERS_CARD_TEXT[this.idTipo];
    this.nombreTipoButtonText = MY_MEDICAL_ORDERS_BUTTON_TEXT[this.idTipo];
    this.nombreConteoDetalle = MY_MEDICAL_ORDER_DETAIL_COUNT_TEXT[this.idTipo];
  }

  private getMedicalOrders(){
    this.medicalOrderService.getList(this.patientData.id, this.idTipo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((medicalOrders:MedicalOrderViewModel[])=>{

        medicalOrders.forEach(medicalOrder=>{

          let item = this.baseService.parseObject<MedicalOrderItemModel>(medicalOrder);
          item.publicProfileAvatar = new PublicProfileAvatarComponentModel();
  
          item.publicProfileAvatar.personId = item.idDoctor;
          item.publicProfileAvatar.avatarUrl = item.avatarDoctor;
          item.publicProfileAvatar.fullName = item.nombreDoctor;
          item.publicProfileAvatar.sex = '9';
          item.publicProfileAvatar.profileType = PublicProfileType.Doctor;

          this.items.push(item);
        });

        this.loading = false;
      });
  }

  viewMoreClick(uidOrdenMedica: string): void {
    let routeDialogModel = new MyOrderSummaryRouteDialogModel();
    const isMobile: boolean = this.breakpointObserver.isMatched('(max-width: 800px)');
    if (isMobile) routeDialogModel.headerName = 'DefaultBackHeaderComponent';
    routeDialogModel.route = `ordenes-medicas/${ uidOrdenMedica }`;
      
    this.routeDialogService.openDialog(routeDialogModel);
  }
}

export class MedicalOrderItemModel extends MedicalOrderViewModel{
  publicProfileAvatar: PublicProfileAvatarComponentModel;
}