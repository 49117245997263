export enum SystemAreas {
    PERFIL_PUBLICO_DOCTOR = 3981,
    PERFIL_PUBLICO_HOSPITAL = 3982,
    PERFIL_PUBLICO_LABORATORIO = 3983,
    PERFIL_PUBLICO_CENTRO_IMAGENES = 3984,
    PERFIL_PUBLICO_FARMACIA = 3985,
    PERFIL_PUBLICO_ASEGURADORA = 3986,
    PERFIL_PUBLICO_SEDES_SURA = 3987,
    PERFIL_PUBLICO_FISIOTERAPIA = 3988,
    PERFIL_PUBLICO_SALUD_Y_BELLEZA = 3989,
    PERFIL_PUBLICO_SERVICIOS_ODONTOLOGICOS = 3990,
    PERFIL_PUBLICO_OPTICAS = 3991,
    PERFIL_PUBLICO_CLINICAS_ATENCION_PRIMARIA = 3992,
    PERFIL_PUBLICO_OTROS_PROVEEDORES = 3993,
    PERFIL_PUBLICO_LABORATORIOS_CON_DESCUENTOS = 3994,
    PERFIL_PUBLICO_IMAGENOLOGIA_CON_DESCUENTOS = 3995,
    DIRECTORIO_DOCTORES = 3741,
    BUSCAR_PERFILES_PUBLICOS = 4113,
    BUSCADOR_WIDGET_PERFILES_PUBLICOS = 4115,    
    MIS_CITAS = 3459,
    MI_SALUD = 4240,
    MIS_ORDENES_MEDICAS = 4246
}

