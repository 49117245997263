
<div class="provider-card-container">
    <mat-card>
        <mat-card-content>
            <div class="header-card-container" (click)="goToProfile($event)">
                
                <div class="avatar">
                    <app-public-profile-avatar [model]="avatarModel"></app-public-profile-avatar>
                </div>
                               
                <!--<app-avatar-initials class="avatar"
                    [url]="provider.foto"
                    [text]="provider.nombre"
                    [id]="provider.id"
                    *ngIf="showAvatarInitials()">
                </app-avatar-initials>
                <div class="avatar icon" *ngIf="!showAvatarInitials()">
                    <mat-icon fontSet="phr-iconset" [ngClass]="getProfileTypeIconClassName()"></mat-icon>
                </div> -->

                <div class="title-card-container">
                    <span class="networks" *ngIf="!isCliniwebCompany" [title]="provider.redes">{{ provider.redes }}</span>
                    <span class="name" [title]="provider.nombre">{{provider.nombre}}</span>
                    <div class="description-container" *ngIf="isDoctorProfile() || isClinicProfile()">
                        <span class="specialty" *ngIf="provider.especialidad" [title]="provider.especialidad">{{provider.especialidad}}</span>
                        <span class="subspecialty" *ngIf="provider.subespecialidad" [title]="provider.subespecialidad">{{provider.subespecialidad}}</span>
                    </div>
                </div>
                <div class="label-container" *ngIf="!isCliniwebCompany">
                    <span *ngIf="isPharmacyProfile() && provider.delivery == 'S'">Delivery</span>
                    <mat-icon *ngIf="getFeatureConceptIconClassName()" fontSet="phr-iconset" [ngClass]="getFeatureConceptIconClassName()"></mat-icon>
                    <span *ngIf="getFeaturesConceptsText()">{{getFeaturesConceptsText() | i18nText:locale.language }}</span>
                </div>
            </div>
            <div class="button-card-container" (click)="goToProfile($event)">
                <button *ngIf="provider.citaEnLineaHab != 'N'" mat-button mat-flat-button class="btn-book-appointment primary" (click)="goToProfile($event)">
                    {{ 'shared.searchResults.providerCard.bookAppointment' | translate:locale.language }}
                </button>
                <button *ngIf="provider.citaEnLineaHab == 'N'" mat-button mat-flat-button class="btn-view-profile secondary outline" (click)="goToProfile($event)">
                    {{ 'shared.searchResults.providerCard.viewProfile' | translate:locale.language }}
                </button>
            </div>
            <div class="locations-container" [ngClass]="{'without-indicator': locations.length == 1 }">
                <mat-tab-group mat-align-tabs="start" [disablePagination]="false" (selectedTabChange)="setLocationSelected($event)">
                    <mat-tab *ngFor="let location of locations; index as i; trackBy: locationTrackBy">
                        <ng-template mat-tab-label>
                            <span class="tab-title">
                                {{getLocationTabTitle(location)}}
                            </span>
                        </ng-template>
                        <div class="tab-content">

                            <div class="location-wrapper" (click)="goToProfile($event)">
                                <mat-icon fontSet="phr-iconset" class="{{getLocationIconClass(location)}} icon"></mat-icon>
                                <span class="location-title" [title]="getLocationTitle(location)" *ngIf="isDoctorProfile()">
                                    {{ getLocationTitle(location) }}
                                </span>
                                <span class="location-address" [title]="getLocationAddress(location)">{{getLocationAddress(location)}}</span>
                                <div *ngIf="location.teleconsulta == 'S'">
                                    <span *ngIf="location.citaEnLineaHabilitada == 'N'">
                                        {{ 'shared.searchResults.providerCard.bookAppointmentByPhone' | translate:locale.language }}
                                    </span>
                                    <a *ngIf="location.citaEnLineaHabilitada == 'S'" class="see-attention-hours-link" (click)="goToProfile($event)">
                                        {{ 'shared.searchResults.providerCard.seeAttentionHours' | translate:locale.language }}
                                    </a>
                                </div>
                                <button mat-button (click)="onClickHowToGetButton(location, $event)" *ngIf="location.teleconsulta != 'S'">
                                    <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-nav"></mat-icon>
                                    {{ 'shared.searchResults.providerCard.howToGetButton' | translate:locale.language }}
                                </button>
                            </div>

                            <div class="attention-schedule-wrapper" *ngIf="!isDoctorProfile()">
                                <mat-icon fontSet="phr-iconset" class="phr-iconset-clock icon"></mat-icon>
                                <span *ngIf="location.objetoDetalle5">
                                    {{location.objetoDetalle5}}
                                </span>
                                <span class="opacity" *ngIf="!location.objetoDetalle5">
                                    {{ 'shared.searchResults.providerCard.unspecified' | translate:locale.language }}
                                </span>
                            </div>
                            <div class="contact-location-wrapper">
                                <div class="contact-wrapper">
                                    <div class="contact-text" *ngIf="!location.objetoDetalle1 && !location.objetoDetalle2 && !location.objetoDetalle4">
                                        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-phone icon"></mat-icon>
                                        <span class="opacity">
                                            {{ 'shared.searchResults.providerCard.unspecified' | translate:locale.language }}
                                        </span>
                                    </div>
                                    <div class="contact-text" *ngIf="location.objetoDetalle1 && location.objetoDetalle1 != location.diLocalidad1">
                                        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-phone icon"></mat-icon>
                                        <span [title]="location.objetoDetalle1">{{ location.objetoDetalle1 }}</span>
                                    </div>
                                    <div class="contact-text" *ngIf="location.objetoDetalle2">
                                        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-phone icon"></mat-icon>
                                        <span [title]="location.objetoDetalle2">{{location.objetoDetalle2}}</span>
                                    </div>
                                    <div class="contact-text" *ngIf="location.objetoDetalle4">
                                        <mat-icon fontSet="phr-iconset" class="phr-iconset-mail icon"></mat-icon>
                                        <span [title]="location.objetoDetalle4">{{location.objetoDetalle4}}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="social-wrapper" *ngIf="!(isDoctorProfile() || isClinicProfile())">
                                <div class="social-text">
                                    <mat-icon fontSet="phr-iconset" class="phr-iconset-url icon"></mat-icon>
                                    <a *ngIf="provider.sitioWeb" [href]="getWebsiteUrl()" target="_blank">Web</a>
                                    <span *ngIf="provider.sitioWeb" class="social-separator">•</span>
                                    <button class="social-networks-btn" mat-button [matMenuTriggerFor]="socialNetworks" (menuOpened)="loadSocialNetworks()">
                                        <span>
                                            {{ 'shared.searchResults.providerCard.socialNetworks' | translate:locale.language }}
                                        </span>                                        
                                        <mat-icon fontSet="phr-iconset" class="phr-iconset-arrow-down"></mat-icon>
                                    </button>
                                    <mat-menu #socialNetworks="matMenu" class="social-networks-panel" >
                                        <div *ngIf="socialNetworksLoaded && !socialNetworksLoading">
                                            <a mat-menu-item 
                                                *ngFor="let socialNetworkElement of socialNetworksList"
                                                [href]="socialNetworkElement.url"
                                                target="_blank">
                                                <mat-icon fontSet="phr-iconset" [ngClass]="socialNetworkElement.iconClassName"></mat-icon>
                                                {{socialNetworkElement.text}}
                                            </a>
                                        </div>
                                        <div class="social-networks-loading-wrapper" *ngIf="socialNetworksLoading">
                                            <mat-spinner [diameter]="18"></mat-spinner>
                                        </div>
                                        <div class="social-networks-empty-wrapper" *ngIf="socialNetworksLoaded && !socialNetworksLoading && socialNetworksList.length == 0">
                                            {{provider.nombre}}
                                            <br/>
                                            {{ 'shared.searchResults.providerCard.socialNetworksEmptyState' | translate:locale.language }}
                                        </div>
                                    </mat-menu>
                                </div>
                            </div> -->
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-card-content>
    </mat-card>
</div>
