import { Component, Inject, OnInit } from '@angular/core';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { PublicProfileAvatarComponentModel, PublicProfileToPublicProfileAvatarComponentModelConverter } from '../../public-profile-avatar/public-profile-avatar.component';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { BaseService } from 'src/app/core/services/base.service';
import { PatientVerificationComponentData, PatientVerificationDataModel } from 'src/app/shared/models/workflow/models/patient-verification-data.model';
import { ComponentDataSetMessage, ComponentFinishLoadingMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { ComponentName } from 'src/app/shared/enums/component-name.enum';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/core/services/user.service';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import * as moment from 'moment';
import { PatientVerificationResponse } from 'src/app/shared/interfaces/patient-verification-response';
import { RequestsToVerifyService } from 'src/app/core/services/requests-to-verify.service';

@Component({
  selector: 'app-patient-verification',
  templateUrl: './patient-verification.component.html',
  styleUrls: ['./patient-verification.component.css']
})
@ComponentLookup(ComponentName.PATIENT_VERIFICATION)
export class PatientVerificationComponent implements IControlComponent, OnInit {

  avatarModel = new PublicProfileAvatarComponentModel();
  componentData: PatientVerificationComponentData;
  data: any;
  errorMessage: string;
  formulario: FormGroup;
  isCliniweb: boolean;
  loading: boolean = false;
  sending: boolean = false;
  maxDate: Date;
  minDate: Date;
  model = new  PatientVerificationDataModel;
  patientName = '';
  titleTextRefence = 'shared.medicalOrder.patientVerification.title';

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private baseService: BaseService,
    private formBuilder: FormBuilder,
    private requestsToVerifyService: RequestsToVerifyService,
    private messageBusService: MessageBusService,
    private userService: UserService
  ) {
    this.configBirthday();
    this.formulario = this.formBuilder.group({
      patientBirthday: [null, Validators.required]
    });
  }

  private configBirthday(): void {
    const currentYear = new Date().getFullYear();
    this.maxDate = new Date();
    this.minDate = new Date(currentYear - 120, 0, 1);
  }

  ngOnInit(): void {
    this.parseControlData();
    this.isCliniweb = this.baseService.isCliniwebCompany();
    this.userService.getUserPersonLoggedInFromService().subscribe({
      next: this.comprobarUsuarioLogeado.bind(this)
    });
    this.sendComponentFinishLoadingMessage();
  }

  private parseControlData(): void {
    this.loading = true;

    if (this.data && this.data.configurationData) {
      this.model = this.data.configurationData as PatientVerificationDataModel;
      this.componentData = this.model.configData;      
      this.patientName = this.model.nombrePaciente;
      this.avatarModel = new PublicProfileToPublicProfileAvatarComponentModelConverter(this.model.profile);
    }

    this.loading = false;
  }

  private comprobarUsuarioLogeado(args: UserPerson | null): void {
    if (!args) return;

    const fechaNacimiento = moment(args.fechaNacimiento);

    this.formulario.get('patientBirthday')?.setValue(fechaNacimiento);

    if (this.formulario.get('patientBirthday')?.getError('matDatepickerMin')) {
      fechaNacimiento.year(1904);
      this.formulario.get('patientBirthday')?.setValue(fechaNacimiento);
    }
  }

  private sendComponentFinishLoadingMessage(): void {
    if (!this.data) return;

    const event = new ComponentFinishLoadingMessage();
    
    event.idFormulario = this.data.idFormulario;
    event.idControl = this.data.idControlPadre;

    this.messageBusService.componentFinishLoading(event);   
  }

  controlHasError(controlName: string, errorName: string): boolean {
    return this.getControl(controlName).hasError(errorName);
  }

  private getControl(controlName: string): AbstractControl {
    return this.formulario.controls[controlName];
  }

  enviar(): void {
    this.formulario.markAllAsTouched();
    
    if (this.formulario.invalid) return;

    const datos: { patientBirthday: string } = this.formulario.value;

    this.sending = true;
    this.formulario.disable();

    this.requestsToVerifyService.validate(datos.patientBirthday, this.model.idVerificacion).subscribe({
      next: this.validateNext.bind(this),
      complete: () => { this.formulario.enable(); this.sending = false; }
    });
  }

  private validateNext(args: PatientVerificationResponse): void {
    let message = new ComponentDataSetMessage();
      
    message.componentName = ComponentName.PATIENT_VERIFICATION;
    message.data = args;

    if (!args.isValid) {
      this.errorMessage = 'shared.medicalOrder.patientVerification.text6';
    } else {
      this.messageBusService.onComponentDataSetMessage(message);
    }
  } 

  getBirthdateControl(): FormControl | null{
    return this.formulario.get('patientBirthday') ? this.formulario.get('patientBirthday') as FormControl : null;
  }
}
