import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { DynamicHostDirective } from './directives/dynamic-host.directive';
import { IFrameResizerDirective } from './directives/iframe-resizer.directive';
import { FormComponent } from './components/form/form.component';
import { DynamicComponent } from './components/dynamic/dynamic.component';
import { FutureAppointmentsComponent } from './components/appointments/future-appointments/future-appointments.component';
import { PastAppointmentsComponent } from './components/appointments/past-appointments/past-appointments.component';
import { CancelledAppointmentsComponent } from './components/appointments/cancelled-appointments/cancelled-appointments.component';
import { AppointmentsTabsComponent } from './components/appointments/appointment-tabs/appointment-tabs.component';
import { SearchDoctorsComponent } from './components/search/search-doctors.component';
import { ProfileFiltersComponent } from './components/profile-filters/profile-filters.component';
import { ChatComponent } from './components/chat/chat.component';
import { MaterialModule } from './material.module';
import { L10nIntlModule, L10nTranslationModule } from 'angular-l10n';
import { HeaderComponent } from './components/headers/header/header.component';
import { HeaderSuraComponent } from './components/headers/header-sura/header-sura.component';
import { I18nTextPipe } from './pipes/i18n-text.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { VirtualConsultationLinkboxComponent } from './components/linkboxs/virtual-consultation-linkbox/virtual-consultation-linkbox.component';
import { MedicalAppointmentsLinkboxComponent } from './components/linkboxs/medical-appointments-linkbox/medical-appointments-linkbox.component';
import { ResultsLinkboxComponent } from './components/linkboxs/results-linkbox/results-linkbox.component';
import { HistoriaClinicaLinkboxComponent } from './components/linkboxs/historia-clinica-linkbox/historia-clinica-linkbox.component';
import { UnreadClinicalExamComponent } from './components/unread-clinical-exam/unread-clinical-exam.component';
import { LoadingComponent } from './components/loading/loading.component';
import { SelectSymptomsComponent } from './components/select-symptoms/select-symptoms.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { CancelAppointmentDialogComponent } from './components/dialogs/cancel-appointment/cancel-appointment-dialog.component';
import { MapDialogComponent } from './components/dialogs/map-dialog/map-dialog.component';
import { SocialNetworksDialogComponent } from './components/dialogs/social-networks-dialog/social-networks-dialog.component';
import { CancelVirtualAttentionDialogComponent } from './components/dialogs/cancel-virtual-attention/cancel-virtual-attention-dialog.component';
import { ChatHistoryDialogComponent } from './components/dialogs/chat-history-dialog/chat-history-dialog.component';
import { MenuComponent } from './components/menu/menu.component';
import { CliniwebMenuComponent } from './components/menu/cliniweb-menu/cliniweb-menu.component';
import { RouterModule } from '@angular/router';
import { MenuMobileComponent } from './components/dialogs/menu-mobile/menu-mobile.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { MenuLinkComponent } from './components/menu-link/menu-link.component';
import { CustomAutocompleteComponent } from './components/custom-autocomplete/custom-autocomplete.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TimelineListComponent } from './components/timeline-list/timeline-list.component';
import { PolicyMenuComponent } from './components/menu/policy/policy-menu.component';
import { PolicyMenuContentComponent } from './components/menu/policy/policy-menu-content.component';
import { ProfileMenuComponent } from './components/menu/profile/profile-menu.component';
import { VirtualConsultationTabsComponent } from './components/virtual-consultation/virtual-consultation-tabs/virtual-consultation-tabs.component';
import { PastVirtualConsultationComponent } from './components/virtual-consultation/past-virtual-consultation/past-virtual-consultation.component';
import { ToastrModule } from 'ngx-toastr';
import { ClinicHistoryTabsComponent } from './components/clinic-history/clinic-history-tabs/clinic-history-tabs.component';
import { AttentionHistoryComponent } from './components/clinic-history/attention-history/attention-history.component';
import { MedicinesComponent } from './components/clinic-history/medicines/medicines.component';
import { MedicalHistoryComponent } from './components/clinic-history/medical-history/medical-history.component';
import { ClinicHistoryShareComponent } from './components/clinic-history/clinic-history-share/clinic-history-share.component';
import { ShareDialogComponent } from './components/clinic-history/share-dialog/share-dialog.component';
import { StudiesLaboratoriesComponent } from './components/clinic-history/studies-laboratories/studies-laboratories.component';
import { HeaderMapfrePanamaComponent } from './components/headers/header-mapfre-panama/header-mapfre-panama.component';
import { SearchProvidersComponent } from './components/search-results/search-providers/search-providers.component';
import { SearchResultsComponent } from './components/search-results/search-results/search-results.component';
import { AutocompleteMultipleSelectionComponent } from './components/autocomplete-multiple-selection/autocomplete-multiple-selection.component';
import { ProviderCardComponent } from './components/search-results/provider-card/provider-card.component';
import { AvatarInitialsComponent } from './components/avatar-initials/avatar-initials.component';
import { CheckAutocompleteComponent } from './components/check-autocomplete/check-autocomplete.component';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { FileViewerDialogComponent } from './components/dialogs/file-viewer-dialog/file-viewer-dialog.component';
import { HeaderTownCenterComponent } from './components/headers/header-town-center/header-town-center.component';
import { HeaderHospitalPaitillaComponent } from './components/headers/header-hospital-paitilla/header-hospital-paitilla.component';
import { WorkflowManagerComponent } from './components/workflows/workflow-manager/workflow-manager.component';
import { OnlineAppointmentCliniwebComponent } from './components/workflows/online-appointment-cliniweb/online-appointment-cliniweb.component';
import { OnlinePaymentWorkflowComponent } from './components/workflows/online-payment/online-payment-workflow.component';
import { WorkflowDynamicComponent } from './components/workflows/workflow-dynamic/workflow-dynamic.component';
import { BaseWorkflowComponent } from './components/workflows/base-workflow/base-workflow.component';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { WorkflowProgressBarComponent } from './components/workflows/workflow-progress-bar/workflow-progress-bar.component';
import { DoctorMessageDialogComponent } from './components/dialogs/doctor-message-dialog/doctor-message-dialog.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { PaymentComponent } from './components/payment/payment.component';
import { PaymentAmountComponent } from './components/payment-amount/payment-amount.component';
import { SelectPatientAppointmentCheckoutComponent } from './components/select-patient-appointment-checkout/select-patient-appointment-checkout.component';
import { AppointmentInfoCheckoutComponent } from './components/appointment-info-checkout/appointment-info-checkout.component';
import { PatientFormAppointmentCheckoutComponent } from './components/patient-form-appointment-checkout/patient-form-appointment-checkout.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { SelectPatientRelationshipAppointmentCheckoutComponent } from './components/select-patient-relationship-appointment-checkout/select-patient-relationship-appointment-checkout.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { PatientContactFormAppointmentCheckoutComponent } from './components/patient-contact-form-appointment-checkout/patient-contact-form-appointment-checkout.component';
import { SummaryAppointmentCheckoutComponent } from './components/summary-appointment-checkout/summary-appointment-checkout.component';
import { LoadingDialogComponent } from './components/dialogs/loading-dialog/loading-dialog.component';
import { StatesRequestVerifyComponent } from './components/states-request-verify/states-request-verify.component';
import { CustomSnackBarComponent } from './components/custom-snack-bar/custom-snack-bar.component';
import { EmailCallbackComponent } from './components/email-callback/email-callback.component';
import { RouteDialogComponent } from './components/dialogs/route-dialog/route-dialog.component';
import { PublicProfileHeaderComponent } from './components/headers/route-dialog-headers/public-profile-header/public-profile-header.component';
import { DefaultHeaderComponent } from './components/headers/route-dialog-headers/default-header/default-header.component';
import { DefaultBackHeaderComponent } from './components/headers/route-dialog-headers/default-back-header/default-back-header.component';
import { DefaultBackWithLinkHeaderComponent } from './components/headers/route-dialog-headers/default-back-with-link-header/default-back-with-link-header.component';
import { ViewUserComponent } from './components/user/view-user/view-user.component';
import { EditUserComponent } from './components/user/edit-user/edit-user.component';
import { ViewDependentComponent } from './components/dependent/view-dependent/view-dependent.component';
import { LogoutBarComponent } from './components/logout-bar/logout-bar.component';
import { IntlTelInputPlaceholderPipe } from './pipes/intl-tel-input-placeholder.pipe';
import { EditDependentComponent } from './components/dependent/edit-dependent/edit-dependent.component';
import { ProfileMenuDialogComponent } from './components/dialogs/profile-menu-dialog/profile-menu-dialog.component';
import { AvatarUploadComponent } from './components/avatar-upload/avatar-upload.component';
import { CliniwebMedicalAppointmentsLinkboxComponent } from './components/linkboxs/cliniweb-medical-appointments-linkbox/cliniweb-medical-appointments-linkbox.component';
import { CliniwebBookAppointmentLinkboxComponent } from './components/linkboxs/cliniweb-book-appointment-linkbox/cliniweb-book-appointment-linkbox.component';
import { CliniwebHomeLayoutComponent } from './components/home-layouts/cliniweb-home-layout/cliniweb-home-layout.component';
import { MostCommonSpecialtiesComponent } from './components/most-common-specialties/most-common-specialties.component';
import { LatestDoctorsComponent } from './components/latest-doctors/latest-doctors.component';
import { PreventiveControlTipsComponent } from './components/preventive-control-tips/preventive-control-tips.component';
import { HealthAlliesComponent } from './components/health-allies/health-allies.component';
import { CliniwebAppointmentTabsComponent } from './components/cliniweb-appointments/cliniweb-appointment-tabs/cliniweb-appointment-tabs.component';
import { CliniwebFutureAppointmentsComponent } from './components/cliniweb-appointments/cliniweb-future-appointments/cliniweb-future-appointments.component';
import { CliniwebAppointmentsComponent } from './components/cliniweb-appointments/cliniweb-appointment/cliniweb-appointment.component';
import { CliniwebPastAppointmentsComponent } from './components/cliniweb-appointments/cliniweb-past-appointments/cliniweb-past-appointments.component';
import { CliniwebCancelAppointmentComponent } from './components/cliniweb-appointments/cliniweb-cancel-appointment/cliniweb-cancel-appointment.component';
import { SearchProvidersV2Component } from './components/search-results/search-providers-v2/search-providers-v2.component';
import { LocationLanguageComponent } from './components/location-language/location-language.component';
import { MatSelectCustomDirective } from './directives/mat-select-custom.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { LegalAgreementsComponent } from './components/legal-agreements/legal-agreements.component';
import { LearnMoreComponent } from './components/learn-more/learn-more.component';
import { DefaultLogoHeaderComponent } from './components/headers/route-dialog-headers/default-logo-header/default-logo-header.component';
import { UserPaymentsListComponent } from './components/user-payments/user-payments-list/user-payments-list.component';
import { UserPaymentComponent } from './components/user-payments/user-payment/user-payment.component';
import { ProviderFiltersDialogComponent } from './components/search-results/provider-filters/provider-filters-dialog/provider-filters-dialog.component';
import { ProviderFiltersBodyComponent } from './components/search-results/provider-filters/provider-filters-body/provider-filters-body.component';
import { ProviderFiltersContentComponent } from './components/search-results/provider-filters/provider-filters-content/provider-filters-content.component';
import { ProviderFiltersTriggerComponent } from './components/search-results/provider-filters/provider-filters-trigger/provider-filters-trigger.component';
import { CompanyLogoComponent } from './components/company-logo/company-logo.component';
import { AnonymousMenuComponent } from './components/menu/anonymous-menu/anonymous-menu.component';
import { AnonymousLanguageMenuComponent } from './components/menu/anonymous-language-menu/anonymous-language-menu.component';
import { AnonymousLoginSignupMenuComponent } from './components/menu/anonymous-login-signup-menu/anonymous-login-signup-menu.component';
import { LoggedInMenuButtonComponent } from './components/menu/logged-in-menu-button/logged-in-menu-button.component';
import { UnlicensedMenuComponent } from './components/menu/unlicensed-menu/unlicensed-menu.component';
import { CenterInputDirective } from './directives/center-input.directive';
import { DatepickerFieldComponent } from './components/datepicker-field/datepicker-field.component';
import { PublicProfileAvatarComponent } from './components/public-profile-avatar/public-profile-avatar.component';
import { TouchMoveListenerDirective } from './directives/touch-move-listener.directive';
import { DisableAutoFocusIfMobileDirective } from './directives/disable-auto-focus-if-mobile.directive';
import { AppointmentInfoDialogComponent } from './components/dialogs/appointment-info-dialog/appointment-info-dialog.component';
import { NextAppointmentDialogComponent } from './components/dialogs/next-appointment-dialog/next-appointment-dialog.component';
import { SearchResultTitleComponent } from './components/search-results/search-result-title/search-result-title.component';
import { PublicProfilePageHeaderComponent } from './components/headers/public-profile-header/public-profile-header.component';
import { CountryFormComponent } from './components/country-form/country-form.component';
import { LoginSingupErrorBannerComponent } from './components/error-banner/login-singup-error-banner/login-singup-error-banner.component';
import { CountryFormCheckoutComponent } from './components/country-form-checkout/country-form-checkout.component';
import { SelectCountryDialogComponent } from './components/dialogs/select-country-dialog/select-country-dialog.component';
import { LegalAgreementsDialogComponent } from './components/dialogs/legal-agreements-dialog/legal-agreements-dialog.component';
import { LearnMoreDialogComponent } from './components/dialogs/learn-more-dialog/learn-more-dialog.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DropdownTriggerForDirective } from './components/dropdown/dropdown-trigger-for.directive';
import { ColaGobloDialogoComponent } from './components/cola-goblo-dialogo/cola-goblo-dialogo.component';
import { CapitalizeDirective } from './directives/capitalize.directive';
import { MedicalOrderWorkflowComponent } from './components/workflows/medical-order-workflow/medical-order-workflow.component';
import { PatientVerificationComponent } from './components/medical-order/patient-verification/patient-verification.component';
import { SummaryMedicalOrderComponent } from './components/medical-order/summary-medical-order/summary-medical-order.component';
import { AgeFromBirthdayPipe } from './pipes/age-from-birthday.pipe';
import { MyHealthComponent } from './components/my-health/my-health.component';
import { MyMedicalOrdersComponent } from './components/my-medical-orders/my-medical-orders.component';
import { DatepickerFlatFieldComponent } from './components/datepicker-flat-field/datepicker-flat-field.component';
import { BottomSheetMenuComponent } from './components/dialogs/bottom-sheet-menu/bottom-sheet-menu.component';
import { MyMedicalOrdersEmptyStateComponent } from './components/my-medical-orders/my-medical-orders-empty-state/my-medical-orders-empty-state.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ErrorDialogComponent } from './components/dialogs/error-dialog/error-dialog.component';
import { GrowthChartsComponent } from './components/growth-charts/growth-charts.component';
import { GrowthChartsTabComponent } from './components/growth-charts/growth-charts-tab/growth-charts-tab.component';
import { GrowthCurvesWorkflowComponent } from './components/workflows/growth-curves-workflow/growth-curves-workflow.component';
import { GrowthChartsTabDataTypeComponent } from './components/growth-charts/growth-charts-tab-data-type/growth-charts-tab-data-type.component';
import { SelectPatientRelationshipComponent } from './components/select-patient-relationship/select-patient-relationship.component';
import { CreatePatientRelationshipComponent } from './components/create-patient-relationship/create-patient-relationship.component';

@NgModule({
  exports: [
    FlexLayoutModule,
    MaterialModule,
    DynamicComponent,
    DynamicHostDirective,
    IFrameResizerDirective,
    MatSelectCustomDirective,
    HeaderComponent,
    HeaderSuraComponent,
    I18nTextPipe,
    OrderByPipe,
    HighlightPipe,
    SafeUrlPipe,
    ReplacePipe,
    CustomCurrencyPipe,
    SearchDoctorsComponent,
    ProfileFiltersComponent,
    LoadingComponent,
    ConfirmDialogComponent,
    ProfileMenuDialogComponent,
    CancelAppointmentDialogComponent,
    MapDialogComponent,
    SocialNetworksDialogComponent,
    CancelVirtualAttentionDialogComponent,
    ChatHistoryDialogComponent,
    MenuComponent,
    CliniwebMenuComponent,
    MenuMobileComponent,
    CustomAutocompleteComponent,
    NgxSkeletonLoaderModule,
    ChatComponent,
    TimelineListComponent,
    PolicyMenuContentComponent,
    PolicyMenuComponent,
    ProfileMenuComponent,
    VirtualConsultationTabsComponent,
    PastVirtualConsultationComponent,
    ToastrModule,
    AutocompleteMultipleSelectionComponent,
    AppointmentsTabsComponent,
    CancelledAppointmentsComponent,
    FutureAppointmentsComponent,
    PastAppointmentsComponent,
    AttentionHistoryComponent,
    ClinicHistoryShareComponent,
    ClinicHistoryTabsComponent,
    MedicalHistoryComponent,
    MedicinesComponent,
    StudiesLaboratoriesComponent,
    HeaderMapfrePanamaComponent,
    HistoriaClinicaLinkboxComponent,
    MedicalAppointmentsLinkboxComponent,
    ResultsLinkboxComponent,
    VirtualConsultationLinkboxComponent,
    SearchResultsComponent,
    SelectSymptomsComponent,
    UnreadClinicalExamComponent,
    FileViewerComponent,
    FileViewerDialogComponent,
    HeaderTownCenterComponent,
    HeaderHospitalPaitillaComponent,
    FormComponent,
    WorkflowManagerComponent,
    DoctorMessageDialogComponent,
    PaymentComponent,
    PaymentAmountComponent,
    LoadingDialogComponent,
    EmailCallbackComponent,
    AvatarUploadComponent,
    CliniwebMedicalAppointmentsLinkboxComponent,
    CliniwebBookAppointmentLinkboxComponent,
    CliniwebHomeLayoutComponent,
    MostCommonSpecialtiesComponent,
    LatestDoctorsComponent,
    PreventiveControlTipsComponent,
    HealthAlliesComponent,
    CliniwebAppointmentTabsComponent,
    CliniwebFutureAppointmentsComponent,
    CliniwebAppointmentsComponent,
    CliniwebPastAppointmentsComponent,
    SummaryAppointmentCheckoutComponent,
    CliniwebCancelAppointmentComponent,
    LocationLanguageComponent,
    LearnMoreComponent,
    LegalAgreementsComponent,
    SafeHtmlPipe,
    StatesRequestVerifyComponent,
    WorkflowProgressBarComponent,
    CompanyLogoComponent,
    CenterInputDirective,
    PublicProfileAvatarComponent,
    DisableAutoFocusIfMobileDirective,
    CountryFormComponent,
    LoginSingupErrorBannerComponent,
    ColaGobloDialogoComponent,
    AgeFromBirthdayPipe,
    MyHealthComponent,
    SummaryMedicalOrderComponent,
    DatepickerFlatFieldComponent,
    BottomSheetMenuComponent,
    SpinnerComponent,
    ErrorDialogComponent,
    GrowthChartsComponent,
    GrowthChartsTabComponent
  ],
  declarations: [
    NotFoundComponent,
    DynamicHostDirective,
    IFrameResizerDirective,
    MatSelectCustomDirective,
    FormComponent,
    DynamicComponent,
    FutureAppointmentsComponent,
    PastAppointmentsComponent,
    CancelledAppointmentsComponent,
    AppointmentsTabsComponent,
    HeaderComponent,
    SearchDoctorsComponent,
    ProfileFiltersComponent,
    HeaderSuraComponent,
    I18nTextPipe,
    OrderByPipe,
    HighlightPipe,
    SafeUrlPipe,
    ReplacePipe,
    CustomCurrencyPipe,
    VirtualConsultationLinkboxComponent,
    MedicalAppointmentsLinkboxComponent,
    ResultsLinkboxComponent,
    HistoriaClinicaLinkboxComponent,
    UnreadClinicalExamComponent,
    LoadingComponent,
    ChatComponent,
    SelectSymptomsComponent,
    ConfirmDialogComponent,
    ProfileMenuDialogComponent,
    CancelAppointmentDialogComponent,
    MapDialogComponent,
    SocialNetworksDialogComponent,
    CancelVirtualAttentionDialogComponent,
    ChatHistoryDialogComponent,
    MenuComponent,
    CliniwebMenuComponent,
    MenuMobileComponent,
    MenuLinkComponent,
    CustomAutocompleteComponent,
    TimelineListComponent,
    PolicyMenuContentComponent,
    PolicyMenuComponent,
    ProfileMenuComponent,
    VirtualConsultationTabsComponent,
    PastVirtualConsultationComponent,
    ClinicHistoryTabsComponent,
    AttentionHistoryComponent,
    MedicinesComponent,
    MedicalHistoryComponent,
    ClinicHistoryShareComponent,
    ShareDialogComponent,
    StudiesLaboratoriesComponent,
    HeaderMapfrePanamaComponent,
    SearchProvidersComponent,
    SearchProvidersV2Component,
    SearchResultsComponent,
    AutocompleteMultipleSelectionComponent,
    ProviderCardComponent,
    AvatarInitialsComponent,
    CheckAutocompleteComponent,
    FileViewerComponent,
    FileViewerDialogComponent,
    HeaderTownCenterComponent,
    HeaderHospitalPaitillaComponent,
    WorkflowManagerComponent,
    BaseWorkflowComponent,
    OnlineAppointmentCliniwebComponent,
    OnlinePaymentWorkflowComponent,
    WorkflowDynamicComponent,
    AuthenticationComponent,
    WorkflowProgressBarComponent,
    DoctorMessageDialogComponent,
    PaymentComponent,
    PaymentAmountComponent,
    SelectPatientAppointmentCheckoutComponent,
    AppointmentInfoCheckoutComponent,
    PatientFormAppointmentCheckoutComponent,
    SelectPatientRelationshipAppointmentCheckoutComponent,
    PatientContactFormAppointmentCheckoutComponent,
    SummaryAppointmentCheckoutComponent,
    LoadingDialogComponent,
    StatesRequestVerifyComponent,
    CustomSnackBarComponent,
    EmailCallbackComponent,
    RouteDialogComponent,
    PublicProfileHeaderComponent,
    DefaultHeaderComponent,
    DefaultBackHeaderComponent,
    DefaultBackWithLinkHeaderComponent,
    ViewUserComponent,
    EditUserComponent,
    ViewDependentComponent,
    LogoutBarComponent,
    IntlTelInputPlaceholderPipe,
    EditDependentComponent,
    AvatarUploadComponent,
    CliniwebMedicalAppointmentsLinkboxComponent,
    CliniwebBookAppointmentLinkboxComponent,
    CliniwebHomeLayoutComponent,
    MostCommonSpecialtiesComponent,
    LatestDoctorsComponent,
    PreventiveControlTipsComponent,
    HealthAlliesComponent,
    CliniwebAppointmentTabsComponent,
    CliniwebFutureAppointmentsComponent,
    CliniwebAppointmentsComponent,
    CliniwebPastAppointmentsComponent,
    CliniwebCancelAppointmentComponent,
    LocationLanguageComponent,
    LearnMoreComponent,
    DefaultLogoHeaderComponent,
    UserPaymentsListComponent,
    LegalAgreementsComponent,
    SafeHtmlPipe,
    UserPaymentComponent,
    ProviderFiltersDialogComponent,
    ProviderFiltersBodyComponent,
    ProviderFiltersContentComponent,
    CompanyLogoComponent,
    ProviderFiltersTriggerComponent,
    AnonymousMenuComponent,
    AnonymousLanguageMenuComponent,
    AnonymousLoginSignupMenuComponent,
    LoggedInMenuButtonComponent,
    UnlicensedMenuComponent,
    CenterInputDirective,
    DatepickerFieldComponent,
    PublicProfileAvatarComponent,
    TouchMoveListenerDirective,
    DisableAutoFocusIfMobileDirective,
    AppointmentInfoDialogComponent,
    NextAppointmentDialogComponent,
    SearchResultTitleComponent,
    PublicProfilePageHeaderComponent,
    CountryFormComponent,
    LoginSingupErrorBannerComponent,
    CountryFormCheckoutComponent,
    SelectCountryDialogComponent,
    LegalAgreementsDialogComponent,
    LearnMoreDialogComponent,
    DropdownComponent,
    DropdownTriggerForDirective,
    CapitalizeDirective,
    ColaGobloDialogoComponent,
    MedicalOrderWorkflowComponent,
    PatientVerificationComponent,
    SummaryMedicalOrderComponent,
    AgeFromBirthdayPipe,
    MyHealthComponent,
    MyMedicalOrdersComponent,
    DatepickerFlatFieldComponent,
    BottomSheetMenuComponent,
    MyMedicalOrdersEmptyStateComponent,
    SpinnerComponent,
    ErrorDialogComponent,
    GrowthChartsComponent,
    GrowthChartsTabComponent,
    GrowthCurvesWorkflowComponent,
    GrowthChartsTabDataTypeComponent,
    SelectPatientRelationshipComponent,
    CreatePatientRelationshipComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    L10nTranslationModule,
    L10nIntlModule,
    RouterModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey    
   }),
   NgxSkeletonLoaderModule,
   InfiniteScrollModule,   
   ToastrModule.forRoot(),
   RecaptchaModule, 
   RecaptchaFormsModule,
   CreditCardDirectivesModule,
   NgxMatIntlTelInputModule
  ],
  entryComponents: [ConfirmDialogComponent, MenuMobileComponent],
  
  /* Every component needs to be added here in order to be rendered after deploy
     If the component is NOT added when angular run the compilaiton and optimization, as these components are render dinamically  
     and NOT refereced in any page, angular will remove it from the bundle result.
  */
  bootstrap: [
    AppointmentsTabsComponent,
    CancelledAppointmentsComponent,
    FutureAppointmentsComponent,
    PastAppointmentsComponent,
    AttentionHistoryComponent,
    ClinicHistoryShareComponent,
    ClinicHistoryTabsComponent,
    MedicalHistoryComponent,
    MedicinesComponent,
    StudiesLaboratoriesComponent,
    HeaderComponent,
    HeaderMapfrePanamaComponent,
    HeaderSuraComponent,
    HistoriaClinicaLinkboxComponent,
    MedicalAppointmentsLinkboxComponent,
    ResultsLinkboxComponent,
    VirtualConsultationLinkboxComponent,
    SearchDoctorsComponent,
    SearchResultsComponent,
    UnreadClinicalExamComponent,
    SelectSymptomsComponent,        
    VirtualConsultationTabsComponent,
    PastVirtualConsultationComponent,
    HeaderTownCenterComponent,
    HeaderHospitalPaitillaComponent,
    OnlineAppointmentCliniwebComponent,
    OnlinePaymentWorkflowComponent,
    MedicalOrderWorkflowComponent,
    GrowthCurvesWorkflowComponent,
    AuthenticationComponent,
    SelectPatientAppointmentCheckoutComponent,
    PatientFormAppointmentCheckoutComponent,
    PatientVerificationComponent,
    SelectPatientRelationshipAppointmentCheckoutComponent,
    PatientContactFormAppointmentCheckoutComponent,
    SummaryAppointmentCheckoutComponent,
    PublicProfileHeaderComponent,
    DefaultHeaderComponent,
    DefaultBackHeaderComponent,
    DefaultBackWithLinkHeaderComponent,
    ViewUserComponent,
    EditUserComponent,
    ViewDependentComponent,
    EditDependentComponent,
    MenuComponent,
    CliniwebMenuComponent,
    CliniwebHomeLayoutComponent,
    CliniwebAppointmentTabsComponent,
    SearchProvidersComponent,
    SearchProvidersV2Component,
    PaymentComponent,
    PaymentAmountComponent,
    DefaultLogoHeaderComponent,
    UserPaymentsListComponent,
    UserPaymentComponent,
    PublicProfilePageHeaderComponent,
    CountryFormCheckoutComponent,
    SummaryMedicalOrderComponent,
    MyHealthComponent,
    MyMedicalOrdersComponent,
    GrowthChartsComponent,
    GrowthChartsTabComponent,
    SelectPatientRelationshipComponent,
    CreatePatientRelationshipComponent
  ]
})
export class SharedModule { }
