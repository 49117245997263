import { UserManagerSettings } from "oidc-client";
import { AuthenticationServiceProvider } from "../../enums/authentication-service-provider";
import { TerritorialDivision } from "./territorial-division.model";

export class PortalPhr {    
    url: string;
    idEmpresa?: number;        
    nombre: string;
    nombreClaseEstilo: string;
    nombreEncabezado: string;
    localizacionDefecto: string;
    urlCorporativo: string;
    titulo: string;
    urlFavicon: string;
    clientesOidc: ConfiguracionServicioAutenticacionPHR[];
    nombreCuentaPaciente: string;
    filtros: string;
    nombrePoliticas: string;
    idEmpresaDestinoConsultaVirtual?: number;
    idEmpresaDestinoDirectorio?: number;    
    nombreEmpresaDestinoDirectorio: string;
    idAreaSistemaDestinoConsultaVirtual: number;
    menuVisible: string;
    configuracionBusquedaLocalidad: string;
    tipoIdentificacionPoliza: number;
    idEmpresasSedes: string;
    analyticsKey: string;
    idsEmpresasLocalidades: string;
    versionPerfilPublico: string;
    nombreMenu: string;
    descargoResponsabilidad: string;
    politicasPrivacidad: string;
    terminosCondiciones: string;
    versionPhr: number;
}

export class PortalPhrConfiguracionLocalidad {
    niveles: string;
    localidadesPorDefecto: TerritorialDivision[];
}

export class OidcUserManagerSettings implements UserManagerSettings {
   authenticationServiceProvider? : AuthenticationServiceProvider;
   readonly popup_redirect_uri?: string;
   authority?: string;
}

export enum PublicProfileVersion {
    V1 = "1.0",
    V2 = "2.0"
}

export enum PHRVersion {
    V1 = 1.0,
    V2 = 2.0
}

export class ConfiguracionServicioAutenticacionPHR {
    id:  number; 
    proveedorServicioAutenticacion : AuthenticationServiceProvider;
    clienteOidc: string;    
}
