import { Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { Subject } from 'rxjs';
import { PublicProfileType } from '../../models/people/profile-search-results.model';
import { UtilsService } from 'src/app/core/services/utils.service';
import { BaseService } from 'src/app/core/services/base.service';
import { PublicProfile } from '../../models/people/public-profile.model';

@Component({
  selector: 'app-public-profile-avatar',
  templateUrl: './public-profile-avatar.component.html',
  styleUrls: ['./public-profile-avatar.component.css']  
})
export class PublicProfileAvatarComponent implements OnInit, OnDestroy {
  _model: PublicProfileAvatarComponentModel;
  @Input() 
      get model(): PublicProfileAvatarComponentModel { return this._model; }
      set model(model: PublicProfileAvatarComponentModel) {
          this._model = model;
          this._model.sex = model.sex?.toLocaleLowerCase();

          if (!this._model.avatarUrl) {
            let initials = this.utilsService.getPersonInitials(model.personId, model.fullName);

            this.profileInitials = initials.initials;
            this.profileInitialsColor = initials.color;

            this.profileTypeIconClassName = this.getProfileTypeIconClassName(model.profileType);

            if (this._model.profileType != PublicProfileType.Doctor)
              this.profileInitialsColor = "var(--grey3)";

            //console.log("Doc without image");
            //console.log("Doc name: '" + model.fullName + "'; DocId: " + model.personId + "; SelectedColor: " + initials.color);
          }
          else 
            this.profileInitialsColor = this.model.defaultBorderColor;
      }
  @Input() withoutBorder = false;
      
  profileType = PublicProfileType;
  profileInitials: string;
  profileInitialsColor: string;
  profileTypeIconClassName: string;
  legacyAvatar: boolean = false;

  constructor(    
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private utilsService: UtilsService,
    private baseService: BaseService
  ) { }

  ngOnInit(): void {
    let companyName = this.baseService.getCompanyName();

    if (companyName.toLocaleLowerCase() == "mapfre-panama-asistencia-pacientes"
      || companyName.toLocaleLowerCase() == "mapfre-pacientes-pty") {
        this.legacyAvatar = true;
    }
  }

  ngOnDestroy() {
    
  }
  
  private getProfileTypeIconClassName(profileType: PublicProfileType) {    
    let className: string = 'phr-iconset-';
    let companyName = this.baseService.getCompanyName();

    if (companyName.toLocaleLowerCase() == "mapfre-panama-asistencia-pacientes")
      return this.getProfileTypeIconClassNameForPanamaAsistencia(profileType);

    switch(profileType){
      case PublicProfileType.Doctor:
      default:
        className += 'estetoscopio';
        break;
      case PublicProfileType.Farmacia:
        className += 'farmacia';
        break;
      case PublicProfileType.Clinica:
        className += 'hospital';
        break;
      case PublicProfileType.Fisioterapia:
          className += 'fisioterapia';
          break;
      case PublicProfileType.Laboratorio:
        className += 'laboratorios';
        break;
      case PublicProfileType.CentroDeImagenes:
        className += 'imagenologia';
        break;  
      case PublicProfileType.ServiciosOdontologicos:
        className += 'odontologia';
        break;
      case PublicProfileType.Opticas:
        className += 'open-eye';
        break;
      case PublicProfileType.ClinicasDeAtencionPrimaria:
        className += 'clinic';
        break;
    }

    return className;
  }

  private getProfileTypeIconClassNameForPanamaAsistencia(profileType: PublicProfileType) {
    let className: string = 'phr-iconset-';

    switch(profileType){
      case PublicProfileType.Doctor:
      default:
        className += 'pa-doc';
        break;
      case PublicProfileType.Farmacia:
        className += 'farmacia';
        break;
      case PublicProfileType.Clinica:
        className += 'pa-hospital';        
        break;
      case PublicProfileType.Fisioterapia:
          className += 'fisioterapia';
          break;
      case PublicProfileType.Laboratorio:
        className += 'pa-lab';
        break;
      case PublicProfileType.CentroDeImagenes:
        className += 'imagenologia';
        break;  
      case PublicProfileType.ServiciosOdontologicos:
        className += 'odontologia';
        break;
      case PublicProfileType.Opticas:
        className += 'open-eye';
        break;
      case PublicProfileType.ClinicasDeAtencionPrimaria:
        className += 'pa-clinica';
        break;
    }

    return className;
  }
}

export class PublicProfileAvatarComponentModel {
    personId: number;
    avatarUrl: string;
    fullName: string;
    sex: string;
    profileType: PublicProfileType;

    useAvatarPadding: boolean = true;
    defaultBorderColor: string = "var(--public-profile-avatar-border-color)";
}

export class PublicProfileToPublicProfileAvatarComponentModelConverter extends PublicProfileAvatarComponentModel {
  constructor(args: PublicProfile) {
    super();
    this.avatarUrl = args.ubicacionFoto;
    this.fullName = args.nombrePersona;
  }
}