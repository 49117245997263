export enum MEDICAL_REQUEST_TYPE {
  GROWTH_CURVE = 57,
  LABORATORY_ORDER = 29,
  MEDICAL_ORDER = 26,
  MEDICAL_PRESCRIPTION = 46,
  RADIOLOGY_ORDER = 32
}

export enum MEDICAL_REQUEST_STATE{
  COMPLETED = 1
}

export enum CONCEPTOS_ID {
  COMENTARIO = 25027
}

export enum TIPO_CONCEPTOS_ID {
  DIAGNOSTICO = 5
}

export interface TitleMapping {
  [key: number]: string;
}

export const MY_MEDICAL_ORDERS_TITLE: TitleMapping = {
  [MEDICAL_REQUEST_TYPE.LABORATORY_ORDER]: 'shared.myMedicalOrders.titleLaboratory',
  [MEDICAL_REQUEST_TYPE.MEDICAL_PRESCRIPTION]: 'shared.myMedicalOrders.titlePrescription',
  [MEDICAL_REQUEST_TYPE.RADIOLOGY_ORDER]: 'shared.myMedicalOrders.titleRadiology',
}

export const MY_MEDICAL_ORDERS_CARD_TEXT: TitleMapping = {
  [MEDICAL_REQUEST_TYPE.LABORATORY_ORDER]: 'shared.myMedicalOrders.cardTextLaboratory',
  [MEDICAL_REQUEST_TYPE.MEDICAL_PRESCRIPTION]: 'shared.myMedicalOrders.cardTextPrescription',
  [MEDICAL_REQUEST_TYPE.RADIOLOGY_ORDER]: 'shared.myMedicalOrders.cardTextRadiology',
}

export const MY_MEDICAL_ORDERS_BUTTON_TEXT: TitleMapping = {
  [MEDICAL_REQUEST_TYPE.LABORATORY_ORDER]: 'shared.myMedicalOrders.buttonTextLaboratory',
  [MEDICAL_REQUEST_TYPE.MEDICAL_PRESCRIPTION]: 'shared.myMedicalOrders.buttonTextPrescription',
  [MEDICAL_REQUEST_TYPE.RADIOLOGY_ORDER]: 'shared.myMedicalOrders.buttonTextRadiology',
}

export const TITLE_MAPPING:TitleMapping = {
  [MEDICAL_REQUEST_TYPE.LABORATORY_ORDER]: 'shared.medicalOrder.summary.laboratoryOrder',
  [MEDICAL_REQUEST_TYPE.MEDICAL_ORDER]: 'shared.medicalOrder.summary.medicalOrder',
  [MEDICAL_REQUEST_TYPE.MEDICAL_PRESCRIPTION]: 'shared.medicalOrder.summary.medicalPrescription',
  [MEDICAL_REQUEST_TYPE.RADIOLOGY_ORDER]: 'shared.medicalOrder.summary.radiologyOrder',
} as const;

export const MY_MEDICAL_ORDER_EMPTY_STATE_TITLE: TitleMapping = {
  [MEDICAL_REQUEST_TYPE.LABORATORY_ORDER]: 'shared.medicalOrder.empty.laboratoryOrderTitle',
  [MEDICAL_REQUEST_TYPE.MEDICAL_ORDER]: 'shared.medicalOrder.empty.medicalOrderTitle',
  [MEDICAL_REQUEST_TYPE.MEDICAL_PRESCRIPTION]: 'shared.medicalOrder.empty.medicalPrescriptionTitle',
  [MEDICAL_REQUEST_TYPE.RADIOLOGY_ORDER]: 'shared.medicalOrder.empty.radiologyOrderTitle',
} as const;

export const MY_MEDICAL_ORDER_EMPTY_STATE_TEXT: TitleMapping = {
  [MEDICAL_REQUEST_TYPE.LABORATORY_ORDER]: 'shared.medicalOrder.empty.laboratoryOrder',
  [MEDICAL_REQUEST_TYPE.MEDICAL_ORDER]: 'shared.medicalOrder.empty.medicalOrderTitle',
  [MEDICAL_REQUEST_TYPE.MEDICAL_PRESCRIPTION]: 'shared.medicalOrder.empty.medicalPrescription',
  [MEDICAL_REQUEST_TYPE.RADIOLOGY_ORDER]: 'shared.medicalOrder.empty.radiologyOrder',
} as const;

export const MY_MEDICAL_ORDER_DETAIL_COUNT_TEXT: TitleMapping = {
  [MEDICAL_REQUEST_TYPE.LABORATORY_ORDER]: 'shared.medicalOrder.summaryDetailText.laboratoryOrder',
  [MEDICAL_REQUEST_TYPE.MEDICAL_ORDER]: 'shared.medicalOrder.summaryDetailText.medicalOrder',
  [MEDICAL_REQUEST_TYPE.MEDICAL_PRESCRIPTION]: 'shared.medicalOrder.summaryDetailText.medicalPrescription',
  [MEDICAL_REQUEST_TYPE.RADIOLOGY_ORDER]: 'shared.medicalOrder.summaryDetailText.radiologyOrder',
} as const;
