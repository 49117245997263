import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageBusService, NavigationBackMessage, OpenSnackBarMessage, SnackBarType } from 'src/app/core/services/message-bus.service';
import { UserService } from 'src/app/core/services/user.service';
import { UpdateUser, UserPerson } from 'src/app/shared/models/people/user-person.model';
import { AvatarUploadComponentOptions, AvatarUploadedFileModel } from '../../avatar-upload/avatar-upload.component';
import { StateType } from 'src/app/shared/enums/state-type.enum';
import { TerritorialDivision } from 'src/app/shared/models/systems/territorial-division.model';
import { TerritorialDivisionsService } from 'src/app/core/services/territorial-divisions.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class EditUserComponent implements OnInit, OnDestroy {
  
  private ngUnsubscribe = new Subject();
  loggedInUser: UserPerson = null as any; 
  formGroup: FormGroup;
  formSubmitted: boolean = false;
  saving: boolean = false;
  translations = {
    man: "",
    women: "",     
  };
  avatarUploadOptions : AvatarUploadComponentOptions;
  minDate: Date;
  maxDate: Date;

  availableCountries: TerritorialDivision[] = [];

  constructor(
    private userService: UserService, 
    @Inject(L10N_LOCALE) public locale: L10nLocale,   
    private translation: L10nTranslationService,
    private formBuilder: FormBuilder,
    private messageBusService: MessageBusService,
    private router: Router,
    private dialog: MatDialog,
    private territorialDivisionService: TerritorialDivisionsService
  ) {
    this.avatarUploadOptions = new AvatarUploadComponentOptions();
    this.avatarUploadOptions.requiredFileType = "image/png,image/jpeg";
    this.avatarUploadOptions.selectFileLabel = "añadir foto";
    this.avatarUploadOptions.changeFileLabel = "Editar";
    this.avatarUploadOptions.showFileOnceUpload = true;
	  this.avatarUploadOptions.edit = true;

    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 120, 0, 1);
    this.maxDate = new Date();
  }

  ngOnInit(): void {
    this.loadAvailableCountries();

    this.formGroup = this.formBuilder.group({      
      nameControl: ['', [Validators.required, Validators.maxLength(200)]],
      lastNameControl: ['', [Validators.required, Validators.maxLength(200)]],
      birthDateControl: ['', Validators.required],
      sexControl: ['', Validators.required],
      countryControl: ['', Validators.required]
    });

    this.translation.onChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
        next: () => {
            this.translations.man = this.translation.translate('man');
            this.translations.women = this.translation.translate('women');

            this.avatarUploadOptions.selectFileLabel = this.translation.translate('shared.viewUser.text8');
            this.avatarUploadOptions.changeFileLabel = this.translation.translate('shared.viewUser.text3');
            this.avatarUploadOptions.changePictureLabel = this.translation.translate('shared.viewUser.text9');
            this.avatarUploadOptions.deleteFileLabel = this.translation.translate('shared.viewUser.text10');
        }
      });

    this.userService.getUserPersonLoggedIn()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(p => {
      if (p)
        this.loggedInUser = p;

        /*if (!this.loggedInUser.ubicacionLogo) {
            let feNacimiento = this.loggedInUser.fechaNacimiento? new Date(this.loggedInUser.fechaNacimiento) : null;
            this.loggedInUser.ubicacionLogo = this.utilsService.getPersonDefaultAvatarUrl(feNacimiento, this.loggedInUser.sexo);
        }*/
        
        //this.loggedInUser.fechaNacimiento = new Date();
        //this.loggedInUser.sexo = "M";

        this.loadUserData(this.loggedInUser);
    });

    // On Header Back click
    this.messageBusService.onNavigateBack().pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onNavigateBackNext.bind(this));
  }

  private onNavigateBackNext(_: NavigationBackMessage): void {
      if (this.formGroup.dirty && this.formGroup.touched) {
        this.beforeCloseAlert();
      } else
        this.router.navigate(['configuracion/usuario']);
  }

  private beforeCloseAlert(): void {
    let dialogData = new DialogData();
    dialogData.title = this.translation.translate("shared.editDependent.cancelTitle");
    dialogData.message = this.translation.translate("shared.editDependent.cancelMessage");
    dialogData.confirmText = this.translation.translate("shared.editDependent.cancelConfirmText");   
    dialogData.cancelText = this.translation.translate("shared.editDependent.cancelCancelText");
    dialogData.headerIconClass = 'phr-iconset-alert';
    dialogData.showHeaderIcon = true;
    dialogData.showCancelButton = true;
    dialogData.loadLicense = true;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      panelClass: 'cancel-edit-person-dialog'
    });

    dialogRef.afterClosed().subscribe(accepted => {
      if (accepted) {
        this.router.navigate(['configuracion/usuario']);
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.messageBusService.refreshProfileMenu();
  }

  loadAvailableCountries(){
    this.availableCountries = this.territorialDivisionService.getAvailableCountries();
  }

  loadUserData(user: UserPerson) {
    if (user.idPaisResidencia > 0 && user.idPaisResidencia != 9){

      if(!this.availableCountries.find(c=> c.idPais == user.idPaisResidencia)){
        let anotherCountry = this.availableCountries.find(c => c.id == 1);

        this.setControlValue("countryControl", anotherCountry?.idPais);
      }
      else{
        this.setControlValue("countryControl", user.idPaisResidencia);
      }
    }

    if (user.idEstadoValidacionIdentidadPersona == StateType.SinValidar) {
      this.setControlValue("nameControl", undefined);
      this.setControlValue("lastNameControl", undefined);
      this.setControlValue("birthDateControl", undefined);
      this.setControlValue("sexControl", undefined);

      return;
    } 

    if (user.nombre)
      this.setControlValue("nameControl", user.nombre);

    if (user.apellido)
      this.setControlValue("lastNameControl", user.apellido);

    if (user.fechaNacimiento)
      this.setControlValue("birthDateControl", user.fechaNacimiento);

    if (user.sexo && user.sexo != "9")
      this.setControlValue("sexControl", user.sexo);
  }

  getControl(controlName: string) {
    return this.formGroup.controls[controlName];
  }

  isControlInvalid(controlName: string) {
    let control = this.getControl(controlName);

    // First check if the form is submitted or control is dirty
    if (!this.formSubmitted && !control.touched)
      return false;

    return control.invalid;
  }

  controlHasError(controlName: string, errorName: string) {
    return this.getControl(controlName).hasError(errorName);
  }
  
  getControlValue(controlName: string) {
    return this.getControl(controlName).value;
  }

  setControlValue(controlName: string, value: any) {
    this.getControl(controlName).setValue(value);
  }

  onUserSaveClick() {
    this.formGroup.markAllAsTouched();
    this.formSubmitted = true;

    if (!this.formGroup.valid)
      return;

    this.saving = true;

    let model: UpdateUser = new UpdateUser();

    model.nombre = this.getControlValue("nameControl");
    model.apellido = this.getControlValue("lastNameControl");
    model.fechaNacimiento = new Date(this.getControlValue("birthDateControl"));
    model.sexo = this.getControlValue("sexControl");
    model.idPais = this.getControlValue("countryControl");
    model.ubicacionLogo = this.loggedInUser.ubicacionLogo ?? model.ubicacionLogo;

    //console.log(model);

    this.userService.updateUser(model).subscribe(updated => {      
      this.loggedInUser.nombre = model.nombre;
      this.loggedInUser.apellido = model.apellido;
      this.loggedInUser.fechaNacimiento = model.fechaNacimiento;
      this.loggedInUser.sexo = model.sexo;
      this.loggedInUser.idPaisResidencia = model.idPais;
      this.loggedInUser.idEstadoValidacionIdentidadPersona = updated.idEstadoValidacionIdentidadPersona ?? StateType.SinValidar;

      this.userService.updateUserLoggedIn(this.loggedInUser);

      this.territorialDivisionService.setCountryApp(model.idPais);

      this.saving = false;

      this.showSuccessToast();

      this.router.navigate(['configuracion/usuario']);
    },
    error => {
      this.saving = false;
    });
  }
  
  showSuccessToast() {
    let message = new OpenSnackBarMessage();
    message.text = this.translation.translate('shared.editUser.text12');
    message.type = SnackBarType.SUCCESS;
    message.duration = 3000;
   
    this.messageBusService.openSnackBar(message);
  }

  onAvatarUploaded(file: AvatarUploadedFileModel) {
    this.loggedInUser.ubicacionLogo = file?.webUrl;
  }

  getBirthdateControl():FormControl | null{
    return this.formGroup.get('birthDateControl') 
      ? this.formGroup.get('birthDateControl') as FormControl 
      : null;
  }
}

