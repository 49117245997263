<div class="public-profile-header-container" [ngClass]="{'show-public-profile-header': publicProfileMenuModel && !publicProfileMenuModel.headerVisible}">
    <button tabindex="-1" mat-icon-button class="btn-close-desktop" (click)="onCloseClick()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Volver"></mat-icon> 
    </button>
    <a class="link" [href]="urlPublicProfile" target="_blank">
        <span>{{'shared.publicProfileHeader.linkText' | translate:locale.language}}</span>
        <mat-icon fontSet="phr-iconset" class="phr-iconset-new-window"></mat-icon>
    </a>
    <button tabindex="-1" mat-icon-button class="btn-close-mobile" (click)="onCloseClick()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon>
    </button>

    <!-- Public Profile menu -->
    <div class="public-profile-menu" *ngIf="publicProfileMenuModel">
        <div mat-card-avatar class="avatar">                
          <app-public-profile-avatar [model]="publicProfileMenuModel.avatarModel"></app-public-profile-avatar>
        </div>
        <span class="profile-name">{{publicProfileMenuModel.profile.nombrePersona}}</span>
        <span class="right">
          <button mat-button mat-flat-button class="btn-pay secondary small" *ngIf="publicProfileMenuModel.profile.receptorPagoElectronico == 'S'" (click)="gotoPayment()">
            {{ 'publicProfiles.doctorHeader.buttonPay' | translate:locale.language }}
          </button>
          <button mat-button mat-flat-button class="btn-appointment primary small" *ngIf="publicProfileMenuModel.profile.citaEnLineaHabilitada == 'S'" (click)="onAppointmentBtnClick()">
              {{ 'publicProfiles.doctorHeader.buttonAppointment' | translate:locale.language }}
          </button>
        </span>
    </div>
</div>