import { Component, Inject, OnInit } from '@angular/core';
import { WorkflowLookup } from 'src/app/shared/decorators/workflow-lookup.decorator';
import { BaseWorkflowComponent } from '../base-workflow/base-workflow.component';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { DOCUMENT, Location } from '@angular/common';
import { BaseService } from 'src/app/core/services/base.service';
import { MessageBusService, ServiceErrorMessage } from 'src/app/core/services/message-bus.service';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { WorkflowStepService } from 'src/app/core/services/workflow-step.service';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { CompanyModel } from 'src/app/shared/models/systems/company.model';
import { StatesRequestVerifyDataModel } from '../../states-request-verify/states-request-verify.component';
import { OnlineAppointmentStatus } from 'src/app/shared/enums/online-appointment-status.enum';
import { GrowthCurvesWorkflowState } from 'src/app/shared/models/workflow/states/growth-curves-workflow-state.model';
import { WorkflowInitialData } from 'src/app/shared/models/process/workflow-initial-data.model';
import { RequestsToVerifyConfirmationResponse, RequestsToVerifyService } from 'src/app/core/services/requests-to-verify.service';
import { SenderPatientRelationship } from 'src/app/shared/models/process/base-request.model';
import { RequestToVerifySummary } from 'src/app/shared/interfaces/request-to-verify-summary';
import { PublicProfile } from 'src/app/shared/models/people/public-profile.model';
import { RequestToVerifyUpdateModel } from 'src/app/shared/models/systems/resquest-to-verify-update.model';

@Component({
  selector: 'app-growth-curves-workflow',
  templateUrl: './growth-curves-workflow.component.html',
  styleUrls: ['./growth-curves-workflow.component.css']
})
@WorkflowLookup('GrowthCurvesWorkflowComponent')
export class GrowthCurvesWorkflowComponent extends BaseWorkflowComponent implements OnInit {

  logoutFn: () => void;
  onlineAppointmentStatus: OnlineAppointmentStatus;
  showState = false;
  data: RequestToVerifySummary;
  state: GrowthCurvesWorkflowState = new GrowthCurvesWorkflowState();
  statesRequestVerifyDataModel: StatesRequestVerifyDataModel;

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    protected baseService: BaseService,
    protected location: Location,
    protected messageBusService: MessageBusService,
    protected publicProfileService: PublicProfileService,
    protected route: ActivatedRoute,
    protected workflowService: WorkflowService,
    protected workflowStepService: WorkflowStepService,
    private appointmentService: AppointmentService,
    private requestToVerifyService: RequestsToVerifyService,
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private translation: L10nTranslationService,
    private requestsToVerifyService: RequestsToVerifyService
  ) {
    super(location, route, baseService, messageBusService, publicProfileService, workflowService, workflowStepService, document);
    this.logoutFn = this.logout.bind(this);
  }

  ngOnInit(): void {  
    if(this.data && this.data.idVerificacion){
      this.checkMedicalOrderStatus();
    } else{
      this.startWorkflow();
    }
  }

  private checkMedicalOrderStatus(): void {
    this.appointmentService.getStatus(this.data.idVerificacion).subscribe(
      this.getStatusNext.bind(this),
      _ => this.messageBusService.serviceError(new ServiceErrorMessage("Error getting request status"))
    );
  }

  private getStatusNext(status: OnlineAppointmentStatus): void {
    this.onlineAppointmentStatus = status;

    if(this.onlineAppointmentStatus == OnlineAppointmentStatus.POR_COMPLETAR 
      || this.onlineAppointmentStatus == OnlineAppointmentStatus.COMPLETADA){
      this.showState = false;
      this.startWorkflow();
    } else{
      this.configStatusView();
      this.showState = true;
    }
  }

  configStatusView(): void {
    this.statesRequestVerifyDataModel = new StatesRequestVerifyDataModel();

    this.statesRequestVerifyDataModel.buttonActionFn = this.gotoHome.bind(this);
    this.statesRequestVerifyDataModel.buttonTextRefI18n = 'shared.statesRequestVerify.buttonHomeText';
    this.statesRequestVerifyDataModel.companyLogoUrl = 'assets/images/cliniweb/logo-cliniweb-phr.svg';
    this.statesRequestVerifyDataModel.mainImageUrl = 'assets/images/cliniweb/Campana.gif';
    this.statesRequestVerifyDataModel.showActionButton = true;
    this.statesRequestVerifyDataModel.showHomeButton = false;
    this.statesRequestVerifyDataModel.textRefI18n = 'shared.statesRequestVerify.scheduledAppointment';
  }

  gotoHome(): void {
    this.router.navigate(['/']);
  }

  startWorkflow(): void {
    // Set this workflow as active
    this.workflowService.setWorkflowTypeActive(STORAGE.GROWTH_CURVES_WORKFLOW_STATE);

    // Get state
    this.getState().subscribe((state: GrowthCurvesWorkflowState)=>{
      this.state = state;

      this.workflowService.setWorkflowDataState(this.state);
      this.init();
    });

    this.progressBarModel.closeFn = this.closeWorkflow.bind(this);
  }

  getState() : Observable<GrowthCurvesWorkflowState> {
    var state = this.workflowService.getWorkflowDataState() as GrowthCurvesWorkflowState;

    if(state){
      return of(state);
    }
    else if(this.data){
      return this.buildStateFromModel(this.data);
    }
    else {
      // This can happend if the user left the tab open with the workflow
      // When session expire the session variable with the workflow state will disappear
      // Go back to home
      this.router.navigate(['/']);
      throw "State expired";
    }    
  }

  buildStateFromModel(sourceModel: RequestToVerifySummary) : Observable<GrowthCurvesWorkflowState> {
    let state = new GrowthCurvesWorkflowState();
    let growthCurvesRequest = new WorkflowInitialData();

    growthCurvesRequest.idTipoSolicitud = sourceModel.idTipoSolicitud;
    growthCurvesRequest.checkoutUrl = window.location.origin + '/curvas-crecimiento/';      
    growthCurvesRequest.localizacion = this.locale.language;

    growthCurvesRequest.perfilPublico = new PublicProfile();
    growthCurvesRequest.perfilPublico.id = sourceModel.idResponsableServicio;
    growthCurvesRequest.perfilPublico.nombrePersona = sourceModel.nombreResponsableServicio;
    growthCurvesRequest.perfilPublico.ubicacionFoto = sourceModel.ubicacionFoto;

    growthCurvesRequest.idPaciente = sourceModel.idPaciente;
    growthCurvesRequest.nombrePaciente = sourceModel.nombrePaciente;
    growthCurvesRequest.relacionEmisorPaciente = sourceModel.relacionEmisorPaciente;

    growthCurvesRequest.workflowPosition = sourceModel.workflowPosition;

    return this.baseService.getCompanyByLicense()
    .pipe(switchMap((company: CompanyModel)=>{
      growthCurvesRequest.idEmpresaOrigen = company.id;
      growthCurvesRequest.nombreEmpresaOrigen = company.name;

      state.requestModel = growthCurvesRequest;
      state.idVerificacion = sourceModel.idVerificacion;

      return of(state);
    }),      
    catchError((err) => {
      this.baseService.handleServiceError(err, "Error getting growth curves request");
      return of(state);
    }));
  }

  closeWorkflow(ruta = 'welcome') {
    // Only show the message if the user is logged in
    if (this.state && this.state.getUserId() > 0 && ruta == 'welcome') {

      let dialogData = new DialogData();
      dialogData.title = this.translation.translate("shared.medicalOrder.dialog.title");
      dialogData.message = this.translation.translate("shared.medicalOrder.dialog.message");
      dialogData.confirmText = this.translation.translate("shared.medicalOrder.dialog.confirmText");   
      dialogData.cancelText = this.translation.translate("shared.medicalOrder.dialog.cancelText");
      dialogData.showHeaderIcon = false;
      dialogData.showCancelButton = true;
      dialogData.loadLicense = true;

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: dialogData,
        panelClass: 'cancel-appointment-dialog'
      });

      dialogRef.afterClosed().subscribe(accepted => {

        if(accepted){
          this._closeWorkflow(ruta);
        }
      });
    }
    else {
      this._closeWorkflow(ruta);
    }    
  }

  private _closeWorkflow(ruta: string) {
    this.messageBusService.showHeader();

    this.workflowCleanup();

    this.router.navigateByUrl(ruta);
  }

  logout(): void {
    this.authService.logout();

    this.stepIndex = 0;
    this.loadStep();
  }

  updateWorkflowStateData(): Observable<any> {
    let updateModel = new RequestToVerifyUpdateModel();
    updateModel.idPacientePhr = this.state.getPatientIdRelationship();
    updateModel.tipoRelacionPacienteUsuario = this.state.getPatientRelationship();
    updateModel.workflowPosition = this.state.getPosition();

    return this.requestToVerifyService.update(this.state.idVerificacion, updateModel)
      .pipe(map((model:RequestToVerifyUpdateModel)=>{
        return true;
      }),
      catchError(err=>{
        this.baseService.handleServiceError(err, "Error updating medical order request");
        return of<void>();
      }));
  }

  workflowCleanup() {
    this.workflowService.removeWorkflowData(STORAGE.GROWTH_CURVES_WORKFLOW_STATE);
  }

  completeWorkflowStateData(): Observable<void> {
    const state: GrowthCurvesWorkflowState = this.workflowService.getWorkflowDataState(STORAGE.GROWTH_CURVES_WORKFLOW_STATE);
    const idResponsableServicio: number = state.getPublicProfile()?.id;

    this.requestsToVerifyService.confirmation(state.getIdVerificacion())
      .subscribe(confirmacionResponse => this.onConfitmationRedirect(confirmacionResponse, idResponsableServicio));

    return of();
  }

  private onConfitmationRedirect(confirmacionResponse: RequestsToVerifyConfirmationResponse, idResponsableServicio: number): void {
    let url = `mi-salud/curvas-crecimiento?idResponsableServicio=${idResponsableServicio}`;

    if (confirmacionResponse.idRelacionPaciente != SenderPatientRelationship.USUARIO) {
      url += `&p=${confirmacionResponse.idPaciente}`;
    }

    this.messageBusService.closeWorkFlowRequest(url)
  }

}
